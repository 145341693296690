@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');
@import url('./vars/app_vars.css');
@import url('./app/googleButton.css');

#root {
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
}

.ant-input:hover {
  border-right-width: var(--ql-border-width-default) !important;
}
.ant-input:focus {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-right-width: var(--ql-border-width-default) !important;
}

.ant-input-group-addon {
  border-width: var(--ql-border-width-default) !important;
  border-color: var(--ql-border-color-default) !important;
  border-left: unset !important;
}

.ant-form input[type='range'] {
  border: unset !important;
}

.ant-input[disabled]:hover {
  border-width: var(--ql-border-width-default) !important;
  border-color: var(--ql-border-color-default) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-width: var(--ql-border-width-default) !important;
  border-color: var(--ql-border-color-default) !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 2px var(--input-padding-horizontal-sm);
}